import { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { persist } from 'mobx-state-tree-persist';

import RangeSelector from '~/components/range_selector';
import { CustomLocalStorage } from '~/mst/models/abstract/report';
import { IAlertMonitor } from '../store';

const ranges = [
  { value: { from: { hours: 3 } } },
  { value: { from: { hours: 6 } } },
  { value: { from: { hours: 12 } } },
  { value: { from: { days: 1 } } },
  { value: { from: { days: 7 } } },
  { value: { from: { days: 30 } } }
];

function RangeSelect({ monitor }: { monitor: IAlertMonitor }) {
  const [persistedStore, setPersistedStore] = useState(null);
  const handleChangeRange = useCallback(
    (newRange) => {
      monitor.setRange(JSON.parse(newRange));
    },
    [monitor]
  );

  useEffect(() => {
    if (monitor) {
      setPersistedStore(
        persist([[monitor.range, { key: 'alert_monitor_range', storage: CustomLocalStorage, whitelist: ['from', 'to', 'is_pinned'] }]])
      );
    }
  }, [monitor]);

  if (persistedStore?.isRehydrated && monitor?.range?.from == null) {
    monitor.range.setFrom({ days: 1 });
  }

  return (
    <RangeSelector
      value={JSON.stringify(monitor?.range?.toJSON())}
      onChange={handleChangeRange}
      isUpdatesOn={monitor?.is_updates_on}
      isPinned={monitor?.range.is_pinned}
      handleOnUpdates={monitor.turnOnUpdates}
      handleOffUpdates={monitor.turnOffUpdates}
      handleTogglePinned={() => monitor.range.togglePinned()}
      pinTimeText="Keep time range across alerts"
      allowCustom
      pinable
      options={ranges}
    />
  );
}

export default observer(RangeSelect);
